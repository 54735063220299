// This Source Code Form is subject to the terms of the Mozilla Public License, v. 2.0.
// If a copy of the MPL was not distributed with this file, You can obtain one at https://mozilla.org/MPL/2.0/.

const selectMsgType = state => state.notification.type
const selectMsgText = state => state.notification.msg

const notification = {
    selectMsgType: selectMsgType,
    selectMsgText: selectMsgText
}

export default notification
